import React from 'react';
import {FaGithub} from "react-icons/fa";
import {CgFileDocument} from "react-icons/cg";


const  ProjectBox = ({projectPhoto, projectName}) => {
  const desc = {
  
    Budget_CalculatorDesc:"Budget calculator to manage monthly expenditures, helps users track and plan their budget efficiently.",
    Budget_CalculatorGithub:"",
    Budget_CalculatorWebsite:"https://iridescent-klepon-5a18bf.netlify.app/budget_calculator/",

    Code_RunnerDesc:"A real-time HTML and CSS code runner thet provides real-time output for HTML and CSS code",
    Code_RunnerGithub:"",
    Code_RunnerWebsite:"https://iridescent-klepon-5a18bf.netlify.app/live%20html%20css%20editor/",
    
    Student_Data_ManagerDesc:"website for collecting and displaying student data.Facilitates easy data management and visualization for students.",
    Student_Data_ManagerGithub:"",
    Student_Data_ManagerWebsite:"https://iridescent-klepon-5a18bf.netlify.app/student_data_manager/",

  }

  let show ='';
  if(desc[projectName + 'Github']===""){
    show="none";
  }
    
  return (
    <div className='projectBox'> 
        <img className='projectPhoto' src={projectPhoto} alt="Project display" /> 
        <div>
            <br />
            <h3>{projectName}</h3>
            <br />
            {desc[projectName + 'Desc']}
            <br />

            <a style={{display:show}} href={desc[projectName + 'Github']} target='_blank'>
              <button className='projectbtn'><FaGithub/> Github</button>
            </a>

            <a href={desc[projectName + 'Website']} target='_blank'>
              <button className='projectbtn'><CgFileDocument/> Demo</button>
            </a>
        </div>
    </div>
  )
}

export default  ProjectBox