import React from 'react';
import ProjectBox from './ProjectBox';
import budget from '../images/budget.png';
import CodeRunner from '../images/CodeRunner.png';
import Student from '../images/Student.png';

const Projects = () => {
  return (
    <div>
      <h1 className='projectHeading'>My <b>Projects</b></h1>
      <div className='project'>
        <ProjectBox projectPhoto={budget} projectName="Budget_Calculator" />
        <ProjectBox projectPhoto={CodeRunner} projectName="Code_Runner" />
        <ProjectBox projectPhoto={Student} projectName="Student_Data_Manager" />
      </div>

    </div>
  )
}

export default Projects