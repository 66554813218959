import React from 'react';
import Lottie from  "lottie-react";
import SpaceBoy from "../LottieFiles/SpaceBoy.json";
import Typed from "./Typed.jsx";
import Tilt from 'react-parallax-tilt';
import Projects from './Projects.jsx';
import Avatar from '../images/Avatar.png';
import {CiCoffeeCup} from "react-icons/ci";
import About from './About.jsx';
import Footer from './Footer.jsx';

const Home = () => {
  return (
    <div >
      <div className='HomePage'>

        <div className='HomeText'>
          <h1>Hi There!</h1>
          <h1>I'M <b>ANIRBAN MAHATA</b></h1>
          <Typed/>   </div>

        <Lottie 
          className="illustration" 
          animationData={SpaceBoy} 
          loop={true} 
        />
        
      </div>

      {/* <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading'>Brief <b>introduction</b></h1>
          <p>
            I love the process of changing a raw idea into a website or a product 
            that impacts lives. 
            I want to do work that challenges me as a developer & work that I can 
            be proud of.<br /><br />
            I am fluent in <b>JAVA</b> and know a bit of <b>Python</b> and am working on a few 
            projects in the <b>MERN</b> stack.<br />
            I plan to learn <b>Next.js</b>, <b>Three.js</b> and<b> Typescript</b> in the near future. <br /><br />
            Also, I love <b>coffee</b> <CiCoffeeCup style={{scale:"1.5", rotate:"15deg"}}/>   
          </p>
        </div>
        <Tilt>
          <img className='Avatar' src={Avatar} alt="" />
        </Tilt>
      </div> */}
           <About/>
      <Projects/>
    </div>
  )
}

export default Home